// import React from "react";
import i18next from "i18next";
import "./i18n";

export function changeLanguage(language) {
    localStorage.setItem("language", language);
    //changeMomentLanguage(language);
    i18next.changeLanguage(language);
    // window.location.reload(true);
  }

export function changeLanguage2(para) {
  let lang=para.get('lang');
  //todo 注意lang为空时 app出现问题
  if(lang != undefined && lang != null && lang != ""){
    changeLanguage(getExistLang(lang));
  }
}

export function getExistLang(userLanguage){
  let language;
  switch (userLanguage) {
    case "zh-CN":
      language = "zh";
      break;
    case "zh":
      language = "zh";
      break;
    case "en":
      language = "en";
      break;
    case "en-US":
      language = "en";
      break;
    case "fr":
      language = "fr";
      break;
    case "es":
      language = "es";
      break;
    case "de":
      language = "de";
      break;
    case "ko":
      language = "ko";
      break;
    case "ru":
      language = "ru";
      break;
    case "ja":
      language = "ja";
      break;
    default:
      language = "en";
    }
    return language;
}

export const ModelingApiStr="api";


export function getStlUrl(origin,projectId,accessToken){
  return `${origin}/api/modeling/project/stl/${projectId}?accessToken=${accessToken}`;
}
export function getJParaUrl(projectId){
  return `api/modeling/project/jsonPara/${projectId}`;
}
export function getGenerateModelUrl(projectId){
  return `api/modeling/project/stl/${projectId}`
}
export function getRestoreProjectUrl(userId,modelingType){
  return `api/modeling/project/restoreOrNew?userId=${userId}&modelingType=${modelingType}`;
}
export function getPrintersUrl(userId){
  return `api/user/${userId}/devicesWithMoreClientRedirect?limit=100`;
}
export function getPrintProjectUrl(deviceId,projectId){
  return `api/modeling/print/${deviceId}/${projectId}`;
}
export function getSlicingProgressUrl(taskId){
  return `api/task/progressClientRedirect/print/${taskId}`;
}
export function getTemplateOriginStlUrl(modelingType){
  return `api/modeling/files/${modelingType}/${modelingType}.stl`;
}

//v1
export function getSlicingProgressUrlV1(taskId){
  return `api/v1/slicing/task/${taskId}`;
}
export function getPrintProjectUrlV1(projectId){
  return `api/v1/project/print/${projectId}`;
}
//