import React, { Component } from 'react';

import "./styles.css";




export class HomeCn extends Component {
  static displayName = HomeCn.name;

  render () {
    return (
      <div className='home'>
        <h1 className="text">Hello, world!</h1>
        <p className="text">Welcome to cloud modeling</p>
        <div class="footer-text">版权所有：江苏威宝仕智能科技有限公司  <a href="http://beian.miit.gov.cn/">苏ICP备15010576号-3</a></div>
      </div>
      
    );



  }
}
