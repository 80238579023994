import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './modeling.css'

import { Canvas, useFrame } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Environment, OrbitControls, Html, useProgress, TransformControls, PerspectiveCamera, Sky } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { Suspense, useRef, useState } from "react";
import { useThree, render, events } from '@react-three/fiber';
import * as THREE from 'three';
import i18next from "i18next";
import * as Setting from "../Setting";

import { Input, Popover,Modal,Spin, Alert, Button ,InputNumber, Select, Space, Cascader,Divider,notification,message,Menu, Dropdown, Checkbox,Switch,Row, Col,Progress  } from 'antd';
//有问题再用antd-mobile重新设计页面

import { HomeOutlined, StarFilled, StarTwoTone,DownOutlined,SyncOutlined,ReloadOutlined } from '@ant-design/icons';

import { post } from 'jquery';
import { createTrue, visitNode } from 'typescript';

const { TextArea } = Input;
const { Option } = Select;


const modelingType="gambit";

const BackgroundScene=()=>{

    // let camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 1000 );
    // camera.position.set( 200, 100, 200 );
  
    let scene = new THREE.Scene();
     
    scene.background = new THREE.Color( 0xa0a0a0 );
    scene.fog = new THREE.Fog( 0xa0a0a0, 200, 1000 );
  
    //
  
    const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444 );
    hemiLight.position.set( 0, 200, 0 );
    scene.add( hemiLight );
  
    const directionalLight = new THREE.DirectionalLight( 0xffffff );
    directionalLight.position.set( 0, 200, 100 );
    directionalLight.castShadow = true;
    directionalLight.shadow.camera.top = 180;
    directionalLight.shadow.camera.bottom = - 100;
    directionalLight.shadow.camera.left = - 120;
    directionalLight.shadow.camera.right = 120;
    scene.add( directionalLight );
    
    // ground
    const groudSize=400;
    const ground = new THREE.Mesh( new THREE.PlaneGeometry( groudSize, groudSize ), new THREE.MeshPhongMaterial( { color: 0x999999, depthWrite: false } ) );
    ground.rotation.x = - Math.PI / 2;
    ground.receiveShadow = true;
    scene.add( ground );
  
    const grid = new THREE.GridHelper( groudSize, 20, 0x000000, 0x000000 );
    grid.material.opacity = 0.2;
    grid.material.transparent = true;
    scene.add( grid );
  return(
    <>
    <primitive object={scene} />
    </>
  );
  }
  

  const StlScene=(props)=>{
          const stl=useLoader(STLLoader,props.stlUrl);
                  let scene = new THREE.Scene();		
                  //const material = new THREE.MeshPhongMaterial( { color: 0x1050ff } );//手机上接近wiibuilder:0x1050ff，
          const material =  new THREE.MeshNormalMaterial();//根据法向量显示的彩色
                  let mesh = new THREE.Mesh( stl, material );
                  mesh.castShadow = true;
          mesh.rotation.set( - Math.PI / 2, 0, 0 );
                  scene.add( mesh );

        return(
          <>
          <primitive object={scene} />
          </>
        );
  }
function Loader() {
    const { active, progress, errors, item, loaded, total } = useProgress();
    return <Html center style={{ color: 'white' }}>{progress} % loaded</Html>;
}


export class Gambit extends Component {
    static displayName = Gambit.name;
   static defaultCamera={ position: [0, 360, 500], rotation: [], fov: 55, near: 1, far: 20000 };
   constructor(props) {
    super(props);

    this.state = {
      userId:"",
      accessToken:"",
      target:[0,0,0],
      stlUrl: "Gambit.stl",//初次呈现的文件
      plainLength:60,
      plainWidth:39.5,
      carNumberText:"",
      lastSaveCarNumberText:"",
      saveButtonDisable:false,
      hoverPaneVisible: false,
      saveModalVisible:false,
      projectSaving:false,
      project:{},
      projectTempName:"Gambit",//上传页面中的名称
      projectTempDescription:"Saved from modeling",//上传页面中的名称
      printModalVisible:false,
      printModalRequesting:false,
      onlineIotPrinters:new Map(),
      meterialOptions:['pla'],//材料选择器先给默认值
      slicingSetting:{
        support:false,
        material:null,
        speed:null,
      },
      printerProfileList:[],
      selectedPrinterText:"No online printer",//选择器显示的文字
      selectedPrinter:null,//打印模型时用  {iotPrinter:{},profile:{}}
      progressModalVisible:false,
      progressInModal:{percent:0,message:i18next.t("modeling:Preparing to slicing")+'...'},
      timerIndex:-1,
      carnumbContentBottom:'5px',
    };
    this.orbitRef = React.createRef();

    // 为了在回调中使用 `this`，这个绑定是必不可少的
    this.generateModel = this.generateModel.bind(this);
  }
  componentDidMount() {

    let para = this.urlParams();
    if(para!=null){
      Setting.changeLanguage2(para);
      let _userId=para.get('userId');
      let _accessToken=para.get('accessToken');
      console.log("userId:"+_userId)
      this.setState({
        userId:_userId,
        accessToken:_accessToken
      });
      if(_userId&&_accessToken)
        this.restoreProject(_userId,_accessToken);//setState的刷新取决于ui，此处需要立马传递数据
    }
  }

  //todo 处理刚进入页面就打印模型
  restoreProject=(userId,token)=>{
    let uri=`api/modeling/project/restoreOrNew?userId=${userId}&modelingType=${modelingType}`;
    fetch(uri, {
      method: 'Get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token}`
      },
    })    
    .then((response) => response.json())  
    .then((project) => {
      //let project = JSON.parse(responseData);//dotnet 返回一个类,json()以后就是对象
      this.setState({
        project:project,
      });
      this.fetchJPara();
    })
    .catch(error => {
      console.error('restoreOrNew error', error);
      });
  }

   fetchJPara=()=>{
    let uri=`api/modeling/project/jsonPara/${this.state.project.id}`;
    fetch(uri, {
      method: 'Get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.state.accessToken}`
      },
    })    
    .then((response) => response.json())  
    .then((responseData) => {      
      let jPara = JSON.parse(responseData.jsonPara);
      if(responseData.type==modelingType&&jPara.stlPath!=null)
       this.setState({
        stlUrl: `api/modeling/project/stl/${this.state.project.id}?accessToken=${this.state.accessToken}`,
        plainLength:jPara.plateSX,
        plainWidth:jPara.plateSY,
        carNumberText:jPara.textContent,
      });
    })
    .catch(error => {
      console.error('restoreOrNew error', error);
    });

    this.getPrinterProfile();
  }
   

  async generateModel(e){
    //e.preventDefault();
    if(this.state.carNumberText==null||this.state.carNumberText==""){
      message.warn(i18next.t("modeling:Text is empty,please enter something"),1.5);
      return;
    }
    this.updateProjectName(this.state.carNumberText);

        
    let carNumberPara={
      textContent:this.state.carNumberText,
      plateSX:this.state.plainLength,
      plateSY:this.state.plainWidth,
      margin:8,
    };
    let body={
      type:modelingType,
      jsonPara:JSON.stringify(carNumberPara)
    };
    
    let uri=`api/modeling/project/stl/${this.state.project.id}`;
    //pulic 文件夹发生写文件会造成此fetch错误而重新刷新页面
    fetch(uri, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.state.accessToken}`
      },
      body: JSON.stringify(body),
    })    
    .then((response) => response.json())  
    .then((responseData) => {
      console.log(responseData);
      //let destStlUrl='./'+data.stlName;
      // 不同的文件需要用不同的文件名，否则setState不起作用
      //let destStlUrl=`api/modeling/result/${data.stlName}`;
      this.setState({
        stlUrl:`${responseData.stlUrl}&&accessToken=${this.state.accessToken}`,
      });
    })
    .catch(error => {
      console.error('Unable to generate stl.', error);
    });
    //test
    // this.setState({
    //   stlUrl:'./dragon.stl',
    // });
    console.log("generateModel");
    //const data = await response.json();
  }

   urlParams=()=> {
       var searchParams;
       if (window.location.search.indexOf("?") == 0 && window.location.search.indexOf("=") > 1) {
           searchParams = new URLSearchParams(window.location.search.substring(1, window.location.search.length));
       }
       return searchParams;
   }



   homeView=()=>{
    this.orbitRef.current.reset();
    // this.orbitRef.current.target=new THREE.Vector3(0,0,0);
    // this.orbitRef.current.object.position.x=0;//set camera
    // this.orbitRef.current.object.position.y=360;
    // this.orbitRef.current.object.position.z=500;    
   }

   handleVisibleChange = hoverPaneVisible => {
    this.setState({ hoverPaneVisible });
  };
  
  saveButtonClick=(e)=>{
    //输入框显示项目名称
    if(this.state.project.name){
      this.setState((state) => ({
        projectTempName: state.project.name
      }));
    }
    else{
      this.setState((state) => ({
        projectTempName: state.carNumberText
      }));
    }
    this.setState({ saveModalVisible:true });
  }

  //目前允许相同的文字多次保存，如需要只保存一次再修改按钮的，disabled={this.state.saveButtonDisable}
  handleSaveOk = () => {
    this.setState({ projectSaving: true });
    // setTimeout(() => {
    //   this.setState({ projectSaving: false, saveModalVisible: false });
    //   //this.context.router.history.goBack();
    // }, 10000);

    let groupUploadSetting= {
      name:this.state.projectTempName,
      categoryIds:[0],
      description:this.state.projectTempDescription,
      accessibility:"private",
      licenseId: 0
    }
    //todo 图片渲染异步执行需要专门的服务更新数据库信息
    let uri=`api/modeling/project/saveToGallery/${this.state.project.id}`;
    fetch(uri, {
      method: 'Post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.state.accessToken}`
      },
      body: JSON.stringify(groupUploadSetting),
    })    
    .then((response) => {
      this.setState({ projectSaving: false, saveModalVisible: false ,saveButtonDisable:response.status==200});//,saveButtonDisable:response.status==200
      if(response.status!=200){
        // notification.error({
        //   message: `结果 `,
        //   description:'保存模型失败',
        //   placement:"bottom",
        //   duration:5
        // });         
        response.json().then(j=>{
          if(j.errorCode==14006){
            message.error(i18next.t("modeling:Private cloud space not enough"),5);
          }
          else
            message.error(i18next.t("modeling:Save model failed")+`:${j.message}`,5);
        });
      }
      else{
        //message.success(`模型已保存至您的模型库。 可到 "我的" -> "私有模型库" 中查看。`,3);
        message.success(i18next.t("modeling:SaveModelSucceed"),3);
      }
    })
    .catch(error => {
      console.error('save to gallery error', error);
      this.setState({ projectSaving: false, saveModalVisible: true ,saveButtonDisable:false});
      message.error(i18next.t("modeling:Save model failed")+`:${error}`,5);
     });

     //update project name
     this.updateProjectName(this.state.projectTempName);
    //  this.setState((state) => ({
    //   lastSaveCarNumberText: state.carNumberText,
    //   saveButtonDisable:true,
    //   }));
  };

  updateProjectName=(name)=>{
    let tempProject=this.state.project;
    tempProject.name=name;
    this.setState({project:tempProject});
  }

  handleSaveCancel = () => {
    this.setState({ projectSaving: false, saveModalVisible: false });
  };


/*1.有在线机器默认选第一个
2.没有机器显示 无在线机器
3.弹出界面时检查上一次选中的机器是否在线
*/
//print
  printButtonClick=(e)=>{
    if(this.state.carNumberText==null||this.state.carNumberText==""){
      message.warn(i18next.t("modeling:Text is empty,please enter something"),1.5);
      return;
    }
    this.setState({ printModalVisible:true });
    this.getPrinters();
  }
  refreshPrinterClick=(e)=>{
    this.setState({ printModalRequesting:true });
    this.getPrinters();
  }
  handlePrintOk=()=>{   
    
    this.printProject();

  }
  handlePrintCancel=()=>{
    this.setState({ printModalVisible:false });
  }

  getPrinters=()=>{
        //todo 后端取获取,从token获取id和cumstomid, 或者直接增加一个接口 传userid获取设备
        
   
    //let uri=`http://8.217.35.137:8083/api/customer/45652930-b0cb-11ec-a4b3-178a834213c5/devicesWithMore?limit=100`
     let uri=`/api/user/${this.state.userId}/devicesWithMoreClientRedirect?limit=100`;
    fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.state.accessToken}`
      },
    })    
    .then((response) => {
      if(response.status==200)
      {       
        response.json().then(j=>{
          let onlineIotPrinters= new Map()
          j.data.forEach(v=>{
            if(v.active){
              onlineIotPrinters.set(v.id.id,v)
            }
          });
          if(onlineIotPrinters.size>0){
            this.setState({
              onlineIotPrinters
            });
            if(!this.state.selectedPrinter){
              let firstPrinter=onlineIotPrinters.values().next().value;
              this.updateSelectedPrinter(this.transportIotPrinter(firstPrinter));
            }           
          }
          else{
            this.emptyOnlineIotPrinters();
          }          
        });        
      }
      else{
        this.emptyOnlineIotPrinters();
        message.error(i18next.t("modeling:Get printer list failed")+`:${response.status}${response.error}`,5);
      }
      this.endPrintModalRequesting();
    })
    .catch(error => {
      // console.error('save to gallery error', error);
      this.emptyOnlineIotPrinters();
      message.error(i18next.t("modeling:Get printer list failed")+`：${error}`,5);
      this.endPrintModalRequesting();
     });
  }

  printProject=()=>{

    this.setState({ printModalRequesting:true}); 
    let printSetting= {
      printerType:this.state.selectedPrinter.profile.printerType,
      material:this.state.slicingSetting.material,
      speed:"normal",
      nozzleSize:0.4,
      supportType: this.state.slicingSetting.support?"everywhere":"none",
      adhesionType:"raft",
      gcode83Name: this.state.selectedPrinter.profile.gcode83Name
    }
    let uri=`api/modeling/print/${this.state.selectedPrinter.iotPrinter.id.id}/${this.state.project.id}`;
    fetch(uri, {
      method: 'Post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.state.accessToken}`
      },
      body: JSON.stringify(printSetting),
    })  
    .then((response) => response.json())  
    .then(jsonData=>{
      if(jsonData.taskId){
        this.endPrintProjectRequest();
        this.showSlicingProgress(jsonData.taskId);
      }
      else{
        this.endPrintModalRequesting();
      }
        
    })//todo get progress
    .catch(error=>{
      this.endPrintModalRequesting();
      message.error(i18next.t("modeling:Print command send error")+`：${error}`,5);
    }); 
  }



  
  emptyOnlineIotPrinters=()=>{
    this.setState(
      {
        onlineIotPrinters:new Map(),
        selectedPrinterText:i18next.t("modeling:No online printer"),
        selectedPrinter:null,
      });
  }

  endPrintProjectRequest=()=>{
    this.setState({ 
      printModalVisible:false,
      printModalRequesting:false 
    });
  }
  endPrintModalRequesting=()=>{
    this.setState(
      {
        printModalRequesting:false,
      });
  }

  onSupportChange=(checked)=>{
    let slicingSetting=this.state.slicingSetting;
    slicingSetting.support=checked;
    this.setState({slicingSetting});
  }
  onPrinterSelect=(value,option)=>{

    // message.info(`选中：${option.key}`,5);

    let selectedPrinter=this.transportIotPrinter(this.state.onlineIotPrinters.get(option.key));
    this.updateSelectedPrinter(selectedPrinter);
  }

  materialSelect=(value,option)=>{
    let slicingSetting=this.state.slicingSetting;
    slicingSetting.material=value;
    this.setState({slicingSetting});
  }

  updateSelectedPrinter=(selectedPrinter)=>{
    let slicingSetting=this.state.slicingSetting;
    slicingSetting.material=selectedPrinter.profile.materials[0];
    this.setState((state) => ({
      selectedPrinter: selectedPrinter,
      selectedPrinterText:selectedPrinter.iotPrinter.name,
      meterialOptions:selectedPrinter.profile.materials,
      slicingSetting,
    }));
  }

  transportIotPrinter=(iotPrinter)=>{
    let profile= this.state.printerProfileList.find(v=>v.printerName===iotPrinter.type)//Todo直接 放在iotprinter里
    return {iotPrinter:iotPrinter,profile:profile}
  }
 
  getPrinterProfile=()=>{
      let uri=`/api/redirect/printer_profile_list_v2`;
      fetch(uri, {
        method: 'Get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })    
      .then((response) => response.json())  
      .then((responseData) => {      
        this.setState({printerProfileList:responseData.printerProfileList})
      })
      .catch(error => {
        console.error('error', error);
      });

  }
//
  showSlicingProgress=(taskId)=>{
    this.setState({progressModalVisible:true});
    //TODO 好像因为网络关系下一次请求会提前到达
    let timerIndex=setInterval(()=>{
      let uri=`/api/task/progressClientRedirect/print/${taskId}`;
      fetch(uri, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.state.accessToken}`
        },
      })  
      .then((response) => response.json())  
      .then(jsonData=>{
        let mes="";
        switch(jsonData.status){
          case "ComputingRequest":
            mes=i18next.t("modeling:Requesting");//"内部通信中";
            break;
          case "TaskCreated":
            mes=i18next.t("modeling:Task Created");//"任务已创建";
            break;
          case "Queuing":
            mes=i18next.t("modeling:Queuing");//"排队中";
            break;
          case "Slicing":
            mes=i18next.t("modeling:Slicing");//"切片中";
            break;
          case "SlicingFinished":
            mes=i18next.t("modeling:Slicing finished");//"切片完成";
            break;
          case "GcodeUploading":
            mes=i18next.t("modeling:Gcode uploading");//"Gcode文件保存中";
            break;
          case "GcodeUploaded":
            mes=i18next.t("modeling:Gcode uploaded");//"Gcode文件保存完成";
            break;
          case "PrintCmdSent":
            mes=i18next.t("modeling:Print command has been sent");//"打印命令已发送给打印机。 可在监控页面查看打印任务进度";//只在当前页面显示后半句
            break;
          case "Error":
            mes=i18next.t("modeling:Error type")+`:${jsonData.errorType};  `+i18next.t("modeling:Error content")+`：${jsonData.errorMes}`;
            break;
          default :
            mes=i18next.t("modeling:Unkown error");
        }
        this.setState({progressInModal:{percent:jsonData.progress,message:mes}});
        if(jsonData.progress==100||jsonData.status==="Error"){
          clearInterval(timerIndex);
        }
      })
      .catch(error=>{
        this.setState((state) => ({progressInModal:{percent:state.progressInModal.progress,message:error}}));
        clearInterval(timerIndex);
      }); 
    },1000);
    this.setState({timerIndex});
  }
  /*uniapp webview跳转到app内部页面
  https://ask.dcloud.net.cn/article/35083
  */
  progressModalClose=()=>{
    clearInterval(this.state.timerIndex);
    this.setState({
      progressModalVisible:false,
      progressInModal:{percent:0,message:i18next.t("slicing:Preparing to slicing")+'...'}
    });    
  }

   render() {
    // gl={{ antialias: true }}
      //const { visible, loading } = this.state;
      //TODO输入框可能要放上面？在uniapp有的时候弹出小键盘不会自动弹输入框不会自动弹上去
       return (
           <div className="App">
               <Canvas dpr={[1, 2]} camera={Gambit.defaultCamera} gl={{ antialias: true }}>
                   <Suspense fallback={<Loader />}>
                       <PerspectiveCamera />
                       <pointLight position={[0, 0, 5]} />
                       <BackgroundScene />
                       <StlScene stlUrl={this.state.stlUrl}/>
                       <OrbitControls ref={this.orbitRef}   />
                   </Suspense>
               </Canvas>
               {/* <div style={{ position: 'absolute', top: 20, left: 50, color: 'white', fontSize: '1.2em' }}>
                <pre>* plainLength  {this.state.plainLength} *</pre>
               </div> */}
              <div>
                <Button className='home-button' shape="round" type="primary" icon={<HomeOutlined />} onClick={this.homeView}></Button>
              </div>
              <div>
                
              </div>
              <div>
                <Button className='print-button' shape="round" type="primary"  onClick={this.printButtonClick}>{i18next.t("modeling:Print")}</Button>
              </div>
              <div className='car-number-content'  >
              <>
                <Button className='save-button' shape="round" type="primary"  onClick={this.saveButtonClick}>{i18next.t("modeling:Save")}</Button>
                <Input  className='car-number-text' 
                  value={this.state.carNumberText}  
                  placeholder={i18next.t("modeling:Enter Text Here")}
                  onChange={(e)=>{this.setState({carNumberText:e.target.value})}}
                />
                <Button className='generator-button2' shape="round" size='middle' type="primary" onClick={this.generateModel}>{i18next.t("modeling:Generate")}</Button>
              </>
              </div>

              <Modal
                visible={this.state.saveModalVisible}
                //"保存到我的私有模型库"
                title={i18next.t("modeling:SaveToMyLibarary")}
                onOk={this.handleSaveOk}
                onCancel={this.handleSaveCancel}
                maskClosable={false}
            
                footer={[
                  <Button key="cancel" shape="round" onClick={this.handleSaveCancel}>
                    {i18next.t("modeling:Cancel")}
                  </Button>,
                  <Button key="save" shape="round" type="primary" loading={this.state.projectSaving} onClick={this.handleSaveOk}>
                    {i18next.t("modeling:OK")}
                  </Button>
                ]}
                >
                <Spin tip={i18next.t("modeling:Saving")+'...'} spinning={this.state.projectSaving}>
                  <p> {i18next.t("modeling:GroupName")}</p>
                  <Input  value={this.state.projectTempName} autoSize={false} onChange={(e)=>{this.setState({projectTempName: e.target.value});}}/>
                  <p>{i18next.t("modeling:GroupDescription")}</p>
                  <TextArea rows={3} 
                    value={this.state.projectTempDescription}  
                    onChange={(e)=>{this.setState({projectTempDescription: e.target.value});}}/>
                  
                </Spin>
              </Modal>

              <Modal
                visible={this.state.printModalVisible}
                title={i18next.t("modeling:Select Printer And Slicing Settings")}
                onOk={this.handlePrintOk}
                onCancel={this.handlePrintCancel}
                maskClosable={false}
                footer={[
                  <Button key="cancel" shape="round" onClick={this.handlePrintCancel}>
                    {i18next.t("modeling:Cancel")}
                  </Button>,
                  <Button key="save" shape="round" type="primary" 
                    disabled={this.state.selectedPrinter==null||this.state.printModalRequesting} 
                    onClick={this.handlePrintOk}>
                    {i18next.t("modeling:Start")}
                  </Button>
                ]}>
                 <Spin tip={i18next.t("modeling:Requesting from server")+'...'} spinning={this.state.printModalRequesting}>
                  <>
                    <label> {i18next.t("modeling:Printer")+':'}</label>
                    <Select 
                      style={{ width: '70%' }}
                      className="printer-selector"
                      value={this.state.selectedPrinterText}
                      onSelect={this.onPrinterSelect}
                      >
                      {
                        Array.from(this.state.onlineIotPrinters.values()).map(printer => (
                        <Option key={printer.id.id} value={printer.name}>
                        {printer.name} 
                        <li>{i18next.t("modeling:Type")+':'} {printer.type}</li>
                        <li>{i18next.t("modeling:Name")+':'} {printer.label}</li>           
                        </Option>))
                      }
                    </Select>
                    <Button key="refresh" className='printer-refresh-button' shape="circle" type="primary" 
                      icon={<ReloadOutlined />}
                      disabled={this.state.printModalRequesting} onClick={this.refreshPrinterClick}></Button>
                  </>            
                </Spin>


                <Divider></Divider>
                <div>
                <p>
                  <label>{i18next.t("modeling:Material")+':'}</label>
                  <Select 
                    style={{ width: '50%' }}
                    className="material-selector"
                    value={this.state.slicingSetting.material}
                    onSelect={this.materialSelect}
                    >
                    {
                      this.state.meterialOptions.map(material => (
                      <Option key={material} value={material}>
                      {material} 
                      </Option>))
                    }
                  </Select>
                </p>
                <Switch  checkedChildren={i18next.t("slicing:Support")} unCheckedChildren={i18next.t("slicing:No Support")} 
                  defaultChecked={this.state.slicingSetting.support}  onChange={this.onSupportChange}/>
                </div>
              </Modal>

              
              <Modal
                visible={this.state.progressModalVisible}
                title={i18next.t("Task Progress")}
                onCancel={this.progressModalClose}
                maskClosable={false}
                footer={[
                  <Button key="close" shape="round" onClick={this.progressModalClose}>
                   {i18next.t("modeling:Close")}
                  </Button>
                ]}>
                <div>
                <p>
                <Progress type="circle" percent={this.state.progressInModal.percent} format={percent => `${percent.toFixed(2)}%`}/>
                </p>
                <label>{this.state.progressInModal.message}</label>
                </div>
              </Modal>
           </div>
       );
    
   }
}




