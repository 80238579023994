import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import { Layout } from './components/Layout';
import { HomeCn } from './components/HomeCn';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { CarNumber } from './components/CarNumber';
import { Gambit } from './components/Gambit';
import { PlateText } from './components/PlateText';
import './custom.css'
import {withTranslation} from "react-i18next";
import i18next from "i18next";

 class App extends Component {
  static displayName = App.name;

  render () {
    let text=i18next.t('modeling:Save');
    let s=text;
    return (
        <div>
          <Route exact path='/modeling' component={HomeCn} />
          <Route path='/counter' component={Counter} />
          <Route path='/modeling/carNumber' component={CarNumber} />
          <Route path='/modeling/gambit' component={Gambit} />
          <Route path='/carNumber' component={CarNumber} />
          <Route path='/gambit' component={Gambit} />
          <Route path='/modeling/plate-text' component={PlateText} />
        </div>
    );
  }
}
export default withTranslation()(App);

// export default withRouter(withTranslation()(App));